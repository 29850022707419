//# imask
// import './modules/imask.js'

//# menuBurger
import './modules/menuBurger.js'

//# dropdown
// import './modules/dropdown.js'

//# scrollTo
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
import './modules/scrollTo.js'

//# spoiler
import './modules/spoiler.js'

// # sliders
import './modules/sliders.js'

//# rating
// import './modules/rating.js'

//# select
import './modules/select.js'

//# dynamic adaptive
import './modules/dynamicAdaptive.js'

//# fixedHeader
import './modules/fixedHeader.js'

//# range
import './modules/range.js'

//# resizeInput
// import './modules/resizeInput.js'

//# quantity
import './modules/quantity.js'

//# tips
// import './modules/tips.js'

//# tabs
import './modules/tabs.js'

//# popups
import './modules/popups.js'

//# moreText
// import './modules/moreText.js'

//# adaptiveSearch
import './modules/adaptiveSearch.js'

//# youtube-embed
// import 'lite-youtube-embed'

//# zoomImage
// import './modules/zoomImage.js'

//# marquee
// import './modules/marquee.js'

//# my script
// import './script.js'

//# LIBRARIES
//# gsap
// import './libraries/gsap.js'
