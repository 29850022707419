import Swiper, { Keyboard, Pagination, Autoplay } from 'swiper'

// # main function
sliders()
function sliders() {
  addClassSwiper()

  if (document.querySelector('.main__slider')) {
    new Swiper('.main__slider', {
      direction: 'horizontal',
      effect: 'slide',
      loop: false,
      speed: 1000,
      spaceBetween: 0,
      slidesPerView: 1,
      watchOverflow: true,

      modules: [Keyboard, Autoplay],

      // pagination: {
      //   el: '.main__pagination',
      //   type: 'bullets',
      //   clickable: true,
      // },

      //# автоматичне відтворення
      autoplay: {
        delay: 4000,
        //# Установіть значення «false» і автовідтворення запускатиметься щоразу після взаємодії
        disableOnInteraction: false,
        //# автовідтворення буде призупинено під час наведення миші на контейнер Swiper
        //# Якщо <disableOnInteraction> також увімкнено, автоматичне відтворення буде зупинено
        pauseOnMouseEnter: false,
        reverseDirection: false,
        stopOnLastSlide: false,
      },

      keyboard: {
        enabled: true,
      },
    })
  }
  if (document.querySelector('.slider-products')) {
    new Swiper('.slider-products', {
      direction: 'horizontal',
      effect: 'slide',
      loop: false,
      // loopAdditionalSlides: 2,
      speed: 1000,
      spaceBetween: 15,
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      slidesPerGroupAuto: true,
      watchOverflow: true,

      modules: [Pagination, Keyboard],

      pagination: {
        el: '.products__pagination',
        type: 'bullets',
        clickable: true,
      },

      keyboard: {
        enabled: true,
      },

      breakpoints: {
        1679.98: {
          spaceBetween: 20,
        },
      },
    })
  }
  if (document.querySelector('.slider-clients')) {
    new Swiper('.slider-clients', {
      direction: 'horizontal',
      effect: 'slide',
      loop: false,
      // loopAdditionalSlides: 2,
      speed: 1000,
      spaceBetween: 10,
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      slidesPerGroupAuto: true,
      watchOverflow: true,

      modules: [Pagination, Keyboard],

      pagination: {
        el: '.clients__pagination',
        type: 'bullets',
        clickable: true,
      },

      keyboard: {
        enabled: true,
      },

      breakpoints: {
        991.98: {
          spaceBetween: 15,
        },
        1679.98: {
          spaceBetween: 20,
        },
      },
    })
  }
}

//# addClassSwiper
function addClassSwiper() {
  const $sliders = document.querySelectorAll('[class*="__swiper"]')

  $sliders.forEach(slider => {
    slider = slider.parentElement
    const wrapper = slider.children[0]
    const slides = Array.from(wrapper.children)

    slider.classList.add('swiper')
    wrapper.classList.add('swiper-wrapper')
    slides.forEach(addClassSlide)
  })
}

//# addClassSlide
function addClassSlide(slide) {
  slide.classList.add('swiper-slide')
}
