//# data-width-responsive
//# добавити в css:
//#   min-width
//#   max-width
//#   box-sizing: content-box;

const inputs = document.querySelectorAll('[data-width-responsive]')

inputs.forEach($input => {
  $input.addEventListener('input', resizeInput.bind(null, $input))
  $input.addEventListener('change', resizeInput.bind(null, $input))

  resizeInput($input)
})

export function resizeInput(el) {
  const length = el.value.length

  el.style.cssText = `width: ${length}ch;`
}
