//# main function
quantity()
function quantity() {
  const quantities = document.querySelectorAll('[data-quantity]')

  quantities.forEach($quantity => {
    const min = +$quantity.dataset.quantity.split(',')[0]
    const max = +$quantity.dataset.quantity.split(',')[1]
    let value = +$quantity.querySelector('[data-value]')?.dataset.value

    if (min && max && value) {
      audit({ min, max, value, $quantity })
      document.addEventListener('click', changeValue)
    }
  })
}

//# changeValue
function changeValue(e) {
  const el = e.target
  if (el.closest('[data-quantity]')) {
    const $quantity = el.closest('[data-quantity]')
    const $value = $quantity.querySelector('[data-value]')
    const min = +$quantity.dataset.quantity.split(',')[0]
    const max = +$quantity.dataset.quantity.split(',')[1]
    let value = +$value.dataset.value

    if (el.closest('[data-quantity-minus]')) {
      if (value === min) return
      --value
    } else if (el.closest('[data-quantity-plus]')) {
      if (value === max) return
      ++value
    }

    $value.dataset.value = value < 10 ? `0${value}` : value

    audit({ min, max, value, $quantity })
  }
}

function audit({ min, max, value, $quantity }) {
  const $plus = $quantity.querySelector('[data-quantity-plus]')
  const $minus = $quantity.querySelector('[data-quantity-minus]')

  if (value === min && !$minus.classList.contains('_disable')) {
    $minus.classList.add('_disable')
  }
  if (value === max && !$plus.classList.contains('_disable')) {
    $plus.classList.add('_disable')
  }
  if (value !== min && value !== max) {
    if ($minus.classList.contains('_disable')) {
      $minus.classList.remove('_disable')
    }
    if ($plus.classList.contains('_disable')) {
      $plus.classList.remove('_disable')
    }
  }
}
