const spoilers = document.querySelectorAll('[data-spoiler][data-media]')

const addTabIndex = $el => $el.setAttribute('tabindex', '-1')
const removeTabIndex = $el => $el.removeAttribute('tabindex')

spoilers.forEach(checkMediaQueries)

window.addEventListener('load', checkOpenSpoilers)
document.addEventListener('click', click)

function checkMediaQueries($spoiler) {
  const mediaQuery = getMediaQuery($spoiler)

  mediaQuery.addListener(() => init($spoiler, mediaQuery))
  init($spoiler, mediaQuery)
}

function init($spoiler, mediaQuery) {
  const controls = $spoiler.querySelectorAll('[data-spoiler-control]')

  if (mediaQuery?.matches) {
    $spoiler.classList.add('_init')
    controls.forEach(close)
    controls.forEach(removeTabIndex)
    return
  }

  $spoiler.classList.remove('_init')
  controls.forEach(open)
  controls.forEach(addTabIndex)
}

function checkOpenSpoilers() {
  const openSpoilers = document.querySelectorAll('[data-spoiler-control][aria-expanded="true"]')
  if (openSpoilers.length > 0) openSpoilers.forEach(open)
}

function click(e) {
  if (e.target.closest('[data-spoiler-control]')) {
    const $control = e.target.closest('[data-spoiler-control]')
    const $parent = $control.closest('[data-spoiler]')

    if (!$parent.classList.contains('_init')) return

    if ($control.closest('[data-spoiler="accordion"]')) {
      accordion($control)
      return
    }

    toggle($control)
  }
}

function accordion($control) {
  const $parent = $control.closest('[data-spoiler="accordion"]')
  const $openSpoiler = $parent.querySelector('[aria-expanded="true"]')

  if ($openSpoiler && $openSpoiler !== $control) close($openSpoiler)

  toggle($control)
}

function toggle($control) {
  if ($control.getAttribute('aria-expanded') === 'true') {
    close($control)
    return
  }
  open($control)
}

function open($control) {
  const $content = document.querySelector(`#${$control.getAttribute('aria-controls')}`)

  $content.style.cssText = `--max-height: ${$content.scrollHeight / 16}rem`
  $control.setAttribute('aria-expanded', true)
  $content.setAttribute('aria-hidden', false)
}

function close($control) {
  const $content = document.querySelector(`#${$control.getAttribute('aria-controls')}`)

  $control.setAttribute('aria-expanded', false)
  $content.setAttribute('aria-hidden', true)
}

function getMediaQuery($parent) {
  if (!$parent.getAttribute('data-media')) return false

  const mediaArray = $parent.dataset.media.split(', ')
  const mediaValue = `(${mediaArray[0]}-width: ${+mediaArray[1] / 16}rem)`

  return window.matchMedia(mediaValue)
}
