import noUiSlider from 'nouislider'
import { resizeInput } from './resizeInput.js'

//# main function
initRanges()
function initRanges() {
  const ranges = document.querySelectorAll('[data-range]')

  if (ranges.length) {
    ranges.forEach($range => {
      const $rangeItem = $range.querySelector('[data-range-item]')
      const inputs = $range.querySelectorAll('[data-input]')
      const step = $range.dataset.step
      const start = $range.dataset.start.split(',').map(i => +i)
      const [min, max] = $range.dataset.minmax.split(',')
      const connect = $range.dataset.connect.split(',').map(item => item.trim() === 'true')

      //# ініціалізація noUiSlider
      noUiSlider.create($rangeItem, {
        //# Кількість маркерів можна встановити за допомогою параметра start
        start: start,
        //# Налаштування встановлює смуги між ручками | [true, false]
        connect: connect,
        //# лінії повзунків
        range: {
          min: +min,
          max: +max,
        },
        //# step
        step: +step,
        //# format
        format: {
          from: value => parseInt(value),
          to: value => parseInt(value),
        },
        //# "drag", "drag-all", "tap", "fixed", "snap", "unconstrained" або "none".
        // behaviour: 'tap',
        //# мінімальна відстань між ручками
        // margin: 30,
        //# обмеження максимальної відстані між двома ручками
        // limit: 50,
        //# Відступ обмежує, наскільки близько до країв повзунка можуть бути маркери.
        // padding: [20, 40],
        //# Орієнтація | "vertical" або "horizontal"
        // orientation: 'horizontal',
        //# Напрямок | "ltr", "rtl"
        // direction: 'ltr',
        //# noUiSlider може надати базову підказку
        tooltips: false,
        //# оживити
        // animate: true,
      })
      //# =============

      $rangeItem.noUiSlider.on('update', valueToInputs.bind(null, inputs))
      inputs.forEach($input => {
        $input.addEventListener('change', setValue.bind(null, $rangeItem, inputs))
      })
      // mergeTooltips($rangeItem, 15, ' - ')
    })
  }
}

//# valueToInputs
function valueToInputs(inputs, values, handle, unencoded, tap, positions, noUiSlider) {
  inputs.forEach(($input, i) => {
    $input.value = values[i]

    if ($input.hasAttribute('data-width-responsive')) {
      resizeInput($input)
    }
  })
}

//# setValue
function setValue($rangeItem, inputs, e) {
  const value = []

  inputs.forEach($input => {
    value.push(parseFloat($input.value))
  })

  $rangeItem.noUiSlider.set(value)
}

//# mergeTooltips
function mergeTooltips(slider, threshold, separator) {
  var textIsRtl = getComputedStyle(slider).direction === 'rtl'
  var isRtl = slider.noUiSlider.options.direction === 'rtl'
  var isVertical = slider.noUiSlider.options.orientation === 'vertical'
  var tooltips = slider.noUiSlider.getTooltips()
  var origins = slider.noUiSlider.getOrigins()

  //# Перемістіть підказки в початковий елемент. Таблиця стилів за замовчуванням обробляє це.
  tooltips.forEach(function (tooltip, index) {
    if (tooltip) {
      origins[index].appendChild(tooltip)
    }
  })

  slider.noUiSlider.on('update', function (values, handle, unencoded, tap, positions) {
    var pools = [[]]
    var poolPositions = [[]]
    var poolValues = [[]]
    var atPool = 0

    //# Призначити першу підказку першому пулу, якщо підказка налаштована
    if (tooltips[0]) {
      pools[0][0] = 0
      poolPositions[0][0] = positions[0]
      poolValues[0][0] = values[0]
    }

    for (var i = 1; i < positions.length; i++) {
      if (!tooltips[i] || positions[i] - positions[i - 1] > threshold) {
        atPool++
        pools[atPool] = []
        poolValues[atPool] = []
        poolPositions[atPool] = []
      }

      if (tooltips[i]) {
        pools[atPool].push(i)
        poolValues[atPool].push(values[i])
        poolPositions[atPool].push(positions[i])
      }
    }

    pools.forEach(function (pool, poolIndex) {
      var handlesInPool = pool.length

      for (var j = 0; j < handlesInPool; j++) {
        var handleNumber = pool[j]

        if (j === handlesInPool - 1) {
          var offset = 0

          poolPositions[poolIndex].forEach(function (value) {
            offset += 1000 - value
          })

          var direction = isVertical ? 'bottom' : 'right'
          var last = isRtl ? 0 : handlesInPool - 1
          var lastOffset = 1000 - poolPositions[poolIndex][last]
          offset = (textIsRtl && !isVertical ? 100 : 0) + offset / handlesInPool - lastOffset

          //# Центруйте цю підказку над ураженими маркерами
          tooltips[handleNumber].innerHTML = poolValues[poolIndex].join(separator)
          tooltips[handleNumber].style.display = 'block'
          tooltips[handleNumber].style[direction] = offset + '%'
        } else {
          //# Сховати цю підказку
          tooltips[handleNumber].style.display = 'none'
        }
      }
    })
  })
}
