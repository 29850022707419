const allTabLists = document.querySelectorAll('[role="tablist"]')

allTabLists.forEach($tabList => $tabList.addEventListener('keydown', changeFocus))
document.addEventListener('click', click)

const isKeyPressed = key => key === 'ArrowRight' || key === 'ArrowLeft' || key === 'ArrowUp' || key === 'ArrowDown' || key === 'Home' || key === 'End'

function changeFocus(e) {
  if (isKeyPressed(e.key)) {
    const $tabFocus = e.target
    const $tabList = $tabFocus.closest('[role="tablist"]')
    const allTabs = Array.from($tabList.querySelectorAll('[role="tab"]'))

    let tabFocusIndex = allTabs.indexOf($tabFocus)

    const increment = () => (++tabFocusIndex >= allTabs.length ? 0 : tabFocusIndex)
    const decrement = () => (--tabFocusIndex < 0 ? allTabs.length - 1 : tabFocusIndex)

    $tabFocus.setAttribute('tabindex', -1)

    switch (e.key) {
      case 'ArrowRight':
        tabFocusIndex = increment()
        break
      case 'ArrowDown':
        e.preventDefault()
        tabFocusIndex = increment()
        break
      case 'ArrowLeft':
        tabFocusIndex = decrement()
        break
      case 'ArrowUp':
        e.preventDefault()
        tabFocusIndex = decrement()
        break
      case 'Home':
        e.preventDefault()
        tabFocusIndex = 0
        break
      case 'End':
        e.preventDefault()
        tabFocusIndex = allTabs.length - 1
        break
    }

    const currentTab = allTabs[tabFocusIndex]

    currentTab.setAttribute('tabindex', 0)
    currentTab.focus()
    toggle(currentTab)
  }
}

function click(e) {
  if (e.target.closest('[role="tab"]')) {
    const $tab = e.target.closest('[role="tab"]')

    toggle($tab)
  }
}

function toggle($tab) {
  if ($tab.getAttribute('aria-selected') === 'true') return

  const $tabList = $tab.closest('[role="tablist"]')
  const allTabs = $tabList.querySelectorAll('[role="tab"]')

  allTabs.forEach(close)
  open($tab)
}

function close($tab) {
  const $panel = document.querySelector(`#${$tab.getAttribute('aria-controls')}`)

  $panel.setAttribute('hidden', true)

  $tab.setAttribute('aria-selected', false)
  $tab.setAttribute('tabindex', -1)
}

function open($tab) {
  const $panel = document.querySelector(`#${$tab.getAttribute('aria-controls')}`)

  $panel.removeAttribute('hidden')

  $tab.setAttribute('aria-selected', true)
  $tab.setAttribute('tabindex', 0)
}
